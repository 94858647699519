<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="close"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapperStyle="{ background: 'transparent' }"
              :offsetTop="16"
              :getContainer="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link href="#topology" title="网络拓扑" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail" class="small-module-title">
              <a-icon type="profile"></a-icon>
              <span> 基本信息</span>
            </div>
            <div style="padding: 0 16px">
              <a-descriptions :column="3">
                <a-descriptions-item label="名称">
                  {{ detail.name }}
                </a-descriptions-item>
                <a-descriptions-item label="描述">
                  {{ detail.description }}
                </a-descriptions-item>
                <a-descriptions-item label="创建时间">
                  {{ detail.created_at }}
                </a-descriptions-item>
                <a-descriptions-item label="更新时间">
                  {{ detail.updated_at }}
                </a-descriptions-item>
                <a-descriptions-item label="状态">
                  <boolean-tag
                    false-color="#1890ff"
                    false-name="生成中"
                    true-name="已生成"
                    :value="detail.completed"
                  ></boolean-tag>
                </a-descriptions-item>
              </a-descriptions>
            </div>
            <div id="topology" style="margin-top: 8px;">
              <div class="small-module-title">
                <a-icon type="apartment"></a-icon>
                <span> 网络拓扑</span>
              </div>
              <div style="padding: 0 16px">
                <network-topology
                  :edges="detail.edges"
                  :nodes="detail.nodes"
                  :id="detail.id"
                  :name="detail.name"
                  :modified="detail.modified"
                ></network-topology>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import { getNetworkTopology } from '@/api/network-topology'
import BooleanTag from '@/components/tag/BooleanTag'
import NetworkTopology from './NetworkTopology'
import store from '@/store'

export default {
  name: 'DetailDrawer',
  components: {
    BooleanTag,
    NetworkTopology
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        description: '',
        nodes: [],
        edges: [],
        completed: false
      }
    }
  },
  methods: {
    show (id) {
      getNetworkTopology(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
    },
    close () {
      this.visible = false
      store.state.cancelRequest.promiseArr.forEach((e, index) => {
        e.cancel('取消请求')
      })
      store.commit('cancelRequest/CLEAR_PROMISE_ARR')
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .small-module-title{
    height: 35px;
    background: #E4F1FF;
    border-radius: 8px;
    color: #096DD9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .button-container {
    text-align: right;
    padding: 0 0 8px 0;

    a {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .active {
      color: #1890ff;
    }
  }

  .unit-card {
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
}
</style>
