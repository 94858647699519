var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":_vm.isUpdate ? '网络拓扑编辑' : '网络拓扑创建',"visible":_vm.visible,"width":800},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"描述","prop":"description"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('a-form-model-item',{attrs:{"label":"网络设备","prop":"networkDevices"}},[_c('multi-source-select',{attrs:{"disabled":_vm.isUpdate,"source-type":"network_device"},model:{value:(_vm.form.networkDevices),callback:function ($$v) {_vm.$set(_vm.form, "networkDevices", $$v)},expression:"form.networkDevices"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }