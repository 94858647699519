<template>
  <a-drawer
    class="edge-infos-drawer"
    title="连接边信息"
    :visible="visible"
    :width="980"
    :destroy-on-close="true"
    :maskStyle="{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }"
    @close="close"
  >
    <a-descriptions v-if="sNode" :column="7" style="margin-bottom: 8px">
      <a-descriptions-item label="设备1" :span="3">
        <a @click="showSourceDrawer(sData.sourceType, sData.sourceId)">{{
          sData.name
        }}</a>
      </a-descriptions-item>
      <a-descriptions-item label="设备类型" :span="2">{{
        $t(`source_type.${sData.sourceType}`)
      }}</a-descriptions-item>
      <a-descriptions-item label="状态" :span="2">
        <source-status-tag
          :status="sData.status"
          style="vertical-align: bottom"
        ></source-status-tag>
      </a-descriptions-item>

      <a-descriptions-item label="设备2" :span="3">
        <a @click="showSourceDrawer(tData.sourceType, tData.sourceId)">{{
          tData.name
        }}</a>
      </a-descriptions-item>
      <a-descriptions-item label="设备类型" :span="2">{{
        $t(`source_type.${tData.sourceType}`)
      }}</a-descriptions-item>
      <a-descriptions-item label="状态" :span="2">
        <source-status-tag
          :status="tData.status"
          style="vertical-align: bottom"
        ></source-status-tag>
      </a-descriptions-item>
    </a-descriptions>

    <a-divider orientation="left">链路统计 ({{edges.length}})</a-divider>

    <a-table
      class="custom-table"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: scrollX, y: 300 }"
      row-key="id"
    >
      <template slot="source" slot-scope="text, record">
        <source-status-tag
          v-if="record.sourcePortStatus"
          :status="record.sourcePortStatus"
          style="vertical-align: bottom; margin-right: 2px;"
        ></source-status-tag>
        <template v-if="record.sourcePortName">
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.sourceName}}</div>
              <div>网口：{{ record.sourcePortName}}</div>
            </template>
            <span>{{ record.sourcePortName }}</span>
          </a-tooltip>
        </template>
        <template v-else>
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.sourceName}}</div>
            </template>
            <span>{{ record.sourceName }}</span>
          </a-tooltip>
        </template>
      </template>
      <template slot="target" slot-scope="text, record">
        <source-status-tag
          v-if="record.targetPortStatus"
          :status="record.targetPortStatus"
          style="vertical-align: bottom;margin-right: 2px;"
        ></source-status-tag>
        <template v-if="record.targetPortName">
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.targetName}}</div>
              <div>网口：{{ record.targetPortName}}</div>
            </template>
            <span>{{ record.targetPortName }}</span>
          </a-tooltip>
        </template>
        <template v-else>
          <a-tooltip>
            <template #title>
              <div>设备：{{ record.targetName}}</div>
            </template>
            <span>{{ record.targetName }}</span>
          </a-tooltip>
        </template>
      </template>
      <template slot="net_if_speed" slot-scope="text">
        <span v-if="text || text === 0">{{ text | human }}</span>
        <span v-else>-</span>
      </template>
      <template slot="net_if_in" slot-scope="text">
        <span v-if="text || text === 0">{{ text | human }}</span>
        <span v-else>-</span>
      </template>
      <template slot="net_if_out" slot-scope="text">
        <span v-if="text || text === 0">{{ text | human }}</span>
        <span v-else>-</span>
      </template>
      <template slot="operation" slot-scope="text, record">
        <delete-button @confirm="deleteEdge(record.id)"></delete-button>
      </template>
    </a-table>

    <network-device-drawer ref="networkDeviceDrawer"></network-device-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
  </a-drawer>
</template>

<script>
import { removeEdges } from '@/utils/networkTopology'
import { humanizeValue } from '@/utils'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import DeleteButton from '@/components/button/DeleteButton'

export default {
  name: 'EdgeInfos',
  components: {
    NetworkDeviceDrawer: () =>
      import('@/components/drawer/NetworkDeviceDrawer'),
    StorageDrawer: () => import('@/components/drawer/StorageDrawer'),
    ServerDrawer: () => import('@/components/drawer/ServerDrawer'),
    SourceStatusTag,
    DeleteButton
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  data () {
    return {
      visible: false,
      loading: false,
      mainEdge: undefined,
      graph: null,
      edges: [], // 当前的所有连线
      sNode: undefined,
      tNode: undefined,
      sData: {},
      tData: {},
      dataSource: [],
      columns: [
        {
          dataIndex: 'sourceName',
          title: '链路出口',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'source'
          }
        },
        {
          dataIndex: 'targetName',
          title: '链路入口',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'target'
          }
        },
        {
          dataIndex: 'net_if_speed',
          title: '速率 (bps)',
          width: 100,
          align: 'center',
          scopedSlots: {
            customRender: 'net_if_speed'
          }
        },
        {
          dataIndex: 'net_if_in',
          title: '入流量 (bps)',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'net_if_in'
          }
        },
        {
          dataIndex: 'net_if_out',
          title: '出流量 (bps)',
          width: 120,
          align: 'center',
          scopedSlots: {
            customRender: 'net_if_out'
          }
        },
        // {
        //   dataIndex: 'usage',
        //   title: '流量占比 (%)',
        //   width: 120
        // },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ]
    }
  },
  methods: {
    show (edges, sourceNode, targetNode, graph) {
      this.graph = graph
      this.sNode = sourceNode
      this.tNode = targetNode
      this.sData = this.sNode.getData()
      this.tData = this.tNode.getData()
      this.loading = true
      edges.forEach(edge => {
        if (edge.visible) this.mainEdge = edge
        else this.edges.push(edge)
      })

      const tableEdges = this.edges

      Promise.all(
        tableEdges.map(
          edge =>
            new Promise((resolve, reject) => {
              const sourceNode = edge.getSourceNode()
              const targetNode = edge.getTargetNode()
              const source = edge.source
              const target = edge.target
              const edgeData = edge.getData()

              const obj = {
                id: edge.id,
                sourceName: sourceNode.data.name,
                sourcePortName: '',
                targetName: targetNode.data.name,
                targetPortName: '',
                net_if_in: edgeData.net.in,
                net_if_out: edgeData.net.out,
                net_if_speed: edgeData.net.speed,
                usage: edgeData.net.usage,
                sourcePortStatus: edgeData.portStatus.source,
                targetPortStatus: edgeData.portStatus.target
              }
              if (source.port) { obj.sourcePortName = sourceNode.getPort(source.port).name }
              if (target.port) { obj.targetPortName = targetNode.getPort(target.port).name }
              resolve(obj)
            })
        )
      ).then(res => {
        this.dataSource = res
        this.loading = false
      })
      this.visible = true
    },
    close () {
      this.visible = false
      this.mainEdge = undefined
      this.edges = []
      this.sNode = undefined
      this.tNode = undefined
      this.sData = {}
      this.tData = {}
      this.dataSource = []
    },
    showSourceDrawer (type, id) {
      if (type === 'network_device') {
        this.$refs.networkDeviceDrawer.show(id)
      } else if (type === 'storage') {
        this.$refs.storageDrawer.show(id)
      } else {
        this.$refs.serverDrawer.show(id)
      }
    },
    deleteEdge (id) {
      this.edges = this.edges.filter(edge => edge.id !== id)
      const edges = removeEdges(this.graph, [id], this.mainEdge, this.edges)
      if (edges) {
        this.dataSource = this.dataSource.filter(item => item.id !== id)
      }
    }
  },
  filters: {
    human (value) {
      return humanizeValue(value)
    }
  }
}
</script>

<style lang="less">
.edge-infos-drawer {
  // .ant-drawer-content-wrapper {
  //   box-shadow: none;
  //   border-left: 1px solid #e3e3e3;
  // }
}
</style>
