import { Graph } from '@antv/x6'
// import { DagreLayout } from '@antv/layout'
import { GridLayout } from '@antv/layout'
import {
  getNetworkDeviceNetworkInterfaceList,
  getNetworkDeviceMonitorHistory
} from '@/api/network-device'
import { getServerInterfaceList, getServerMonitorHistory } from '@/api/server'
import {
  getStorageInterfaceList,
  getStorageMonitorHistory
} from '@/api/storage'
import {
  getHypervisorList,
  getHypervisorMonitorHistory
} from '@/api/hypervisor'
import { humanizeValue } from '@/utils'
import NetworkNode from '@/components/NetworkNode'

export const NODE_WIDTH = 64
export const NODE_HEIGHT = 64

export const EDGE_GRAY = '#b0bbc7'
export const EDGE_RED = '#EB3F3E'
export const EDGE_YELLOW = '#F4C516'
export const EDGE_GREEN = '#33a11e'
export const EDGE_DEFAULT = '#b0bbc7'

Graph.registerVueComponent('network-node', {
  template: '<network-node></network-node>',
  components: {
    NetworkNode
  }
})

// 自动布局初始化
export function initLayout () {
  // return new DagreLayout({
  //   type: 'dagre',
  //   rankdir: 'TB',
  //   ranksep: 60,
  //   nodesep: 60,
  //   controlPoints: false
  // })
  return new GridLayout({
    type: 'grid',
    nodeSize: 280
  })
}

// 链接桩配置
export const networkTopologyPortsGroup = {
  groups: {
    temporary: {
      position: {
        name: 'absolute',
        args: { x: 0.5, y: 0.5 }
      },
      attrs: {
        circle: {
          r: 6,
          magnet: true,
          stroke: '#31d0c6',
          strokeWidth: 2,
          fill: '#fff'
        }
      },
      zIndex: 99
    },
    used: {
      position: {
        name: 'absolute',
        args: { x: 0.5, y: 0.35 }
      },
      attrs: {
        circle: {
          r: 30,
          magnet: false,
          stroke: 'transparent',
          strokeWidth: 1,
          fill: 'transparent'
        }
      },
      zIndex: -999
    },
    main: {
      position: {
        name: 'absolute',
        args: { x: 0.5, y: 0.35 }
      },
      attrs: {
        circle: {
          r: 30,
          magnet: false,
          stroke: 'transparent',
          strokeWidth: 1,
          fill: 'transparent'
        }
      },
      zIndex: -999
    }
  },
  items: [{ group: 'main', id: 'main' }]
}

// 边默认label配置
export const networkTopologyEdgeLabel = [
  {
    markup: [
      {
        tagName: 'rect',
        selector: 'labelBody'
      },
      {
        tagName: 'text',
        selector: 'labelText'
      },
      {
        tagName: 'text',
        selector: 'labelValue'
      }
    ],
    attrs: {
      labelText: {
        text: '',
        fill: '#6d6d6d',
        textAnchor: 'middle',
        textVerticalAnchor: 'bottom',
        refY: -2,
        fontSize: 11
      },
      labelValue: {
        text: '',
        fill: '#ce9d68',
        textAnchor: 'middle',
        textVerticalAnchor: 'top',
        fontSize: 13,
        fontWeight: 500
      },
      labelBody: {
        ref: 'labelText',
        refX: -7,
        refY: -3,
        refWidth: '100%',
        refHeight: '100%',
        refWidth2: 14,
        refHeight2: 24,
        fill: 'transparent',
        strokeWidth: 0,
        rx: 5,
        ry: 5
      }
    }
  },
  {
    markup: [
      {
        tagName: 'rect',
        selector: 'labelBody'
      },
      {
        tagName: 'text',
        selector: 'labelText'
      },
      {
        tagName: 'text',
        selector: 'labelValue'
      }
    ],
    attrs: {
      labelText: {
        text: '',
        fill: '#6d6d6d',
        textAnchor: 'middle',
        textVerticalAnchor: 'bottom',
        refY: -2,
        fontSize: 11
      },
      labelValue: {
        text: '',
        fill: '#4cb0e3',
        textAnchor: 'middle',
        textVerticalAnchor: 'top',
        fontSize: 13,
        fontWeight: 500
      },
      labelBody: {
        ref: 'labelText',
        refX: -7,
        refY: -3,
        refWidth: '100%',
        refHeight: '100%',
        refWidth2: 14,
        refHeight2: 24,
        fill: 'transparent',
        strokeWidth: 0,
        rx: 5,
        ry: 5
      }
    }
  }
]
/**
 * label操作
 * @param {*} text label标题
 * @param {*} edge
 * @param {*} data 数据值
 * @param {*} index label下标
 * @param {*} distance 处于线的什么位置
 */
export function editLabel (edge, text, data, index, distance) {
  edge.setLabelAt(index, {
    markup: networkTopologyEdgeLabel[index].markup,
    attrs: {
      labelText: {
        ...networkTopologyEdgeLabel[index].attrs.labelText,
        text
      },
      labelValue: {
        ...networkTopologyEdgeLabel[index].attrs.labelValue,
        text: humanizeValue(data)
      },
      labelBody: networkTopologyEdgeLabel[index].attrs.labelBody
    },
    position: { distance }
  })
}

// 边样式配置
export const networkTopologyEdgeAttrs = {
  attrs: {
    line: {
      stroke: EDGE_DEFAULT,
      strokeWidth: 1,
      targetMarker: '',
      strokeDasharray: [8, 6],
      style: {
        animation: 'topology-edge 80s infinite linear'
      }
    }
  },
  visible: false
}

// 创建节点
export function createNetworkNode (
  graph,
  name,
  sourceType,
  sourceId,
  deviceType,
  deviceTypeName,
  status
) {
  return graph.createNode({
    shape: 'vue-shape',
    width: 40,
    height: 40,
    component: 'network-node',
    data: {
      name,
      sourceId,
      sourceType,
      status,
      deviceType,
      deviceTypeName
    },
    ports: [],
    zIndex: 9
  })
}

/**
 * 添加节点
 * @param {*} graph
 * @param {*} nodes
 * @param {*} hasPort 画布的canLine状态 用来判断是否要添加临时链接桩来进行线的创建
 */
export function networkTopologyAddNodes (graph, nodes, hasPort = false) {
  nodes.forEach(node => {
    if (node.shape === 'vue-shape') {
      node.width = NODE_WIDTH
      node.height = NODE_HEIGHT
      node.ports = JSON.parse(JSON.stringify(networkTopologyPortsGroup))
      if (hasPort) {
        node.ports.items.push({ group: 'temporary', id: 'temporary' })
      }
    }
    graph.addNode(node)
  })
}

/**
 * 创建边
 * @param {*} graph
 * @param {*} infos 即将添加的边的信息
 * @param {*} showMessage 是否要展示操作返回结果的弹窗
 * @param {*} isInit 是否为初次渲染（若为初次渲染则不调用更新主线的函数）
 */
export function networkTopologyAddEdge (
  graph,
  infos
) {
  const { edge, nodes, portNames } = { ...infos }
  const source = edge[0]
  const target = edge[1]
  const sourceNode = nodes[0]
  const targetNode = nodes[1]
  let mainEdge

  // 1.若是连接到port 先添加该port到node中 先判断是否已经存在
  if (source.port && !sourceNode.hasPort(source.port)) {
    sourceNode.addPort({
      id: source.port,
      group: 'used',
      disabled: true,
      name: portNames.sourcePortName
    })
  }
  if (target.port && !targetNode.hasPort(target.port)) {
    targetNode.addPort({
      id: target.port,
      group: 'used',
      disabled: true,
      name: portNames.targetPortName
    })
  }

  // 2.判断是否要展示可见的主线
  // 若起止点为一个 则路由变为智能路由
  const edges = graph.getEdges()
  const obj1 = { cell: source.cell, port: 'main' }
  const obj2 = { cell: target.cell, port: 'main' }
  const result1 = edges.some(edge => {
    const obj1Str = JSON.stringify(obj1)
    const obj2Str = JSON.stringify(obj2)
    const sStr = JSON.stringify(edge.getSource())
    const tStr = JSON.stringify(edge.getTarget())
    return (
      (sStr === obj1Str && tStr === obj2Str) ||
      (sStr === obj2Str && tStr === obj1Str)
    )
  })
  if (!result1) {
    // 两设备间不存在主线 进行添加
    const edgeInfo = {
      ...networkTopologyEdgeAttrs,
      visible: true,
      labels: networkTopologyEdgeLabel,
      source: { ...edge[0], port: 'main' },
      target: { ...edge[1], port: 'main' }
    }
    if (obj1.cell === obj2.cell) {
      edgeInfo.router = {
        name: 'orth'
      }
      edgeInfo.connector = {
        name: 'rounded',
        args: {
          radius: 8
        }
      }
    }

    mainEdge = graph.addEdge(edgeInfo)
    mainEdge.setData({
      status: undefined,
      maxNet: {
        in: undefined,
        out: undefined,
        usage: undefined
      }
    })
  }

  // 3.添加当前创建的连线且不可见的渲染到画布上
  const newSourceStr = JSON.stringify(source)
  const newTargetStr = JSON.stringify(target)

  const sourceEdges = graph.getConnectedEdges(sourceNode)
  const result = sourceEdges.some(edge => {
    const source = edge.getSource()
    const target = edge.getTarget()
    return (
      JSON.stringify(source) === newSourceStr &&
      JSON.stringify(target) === newTargetStr
    )
  })
  if (result) this.$message.info('已存在，无需再创建连接！')
  else if (!result) {
    const newEdge = graph.addEdge({
      ...networkTopologyEdgeAttrs,
      source,
      target
    })
    this.$message.success('连接创建成功！')
    // 1.获取边的相关数据
    getEdgeData(newEdge).then(edge => {
      // 2.获取该边的所有连线
      const allConnectEdges = getConnectEdges(graph, edge)
      // 3.根据获取的所有边更新样式
      updateMainEdgeStyle(allConnectEdges)
    })
  }
}

/**
 * 3.获取某两个设备之间的所有连线
 * @param {*} graph 画布
 * @param {*} childrenEdge 根据某一条边获取到两端的设备
 * @param {*} node1 直接传入设备
 * @param {*} node2 直接传入设备
 */
export function getConnectEdges (
  graph,
  childrenEdge = undefined,
  node1 = undefined,
  node2 = undefined
) {
  const edges = []
  let n1 = node1
  let n2 = node2
  if (childrenEdge) {
    n1 = childrenEdge.getSourceNode()
    n2 = childrenEdge.getTargetNode()
  }
  const n1Edges = graph.getConnectedEdges(n1)
  n1Edges.forEach(edge1 => {
    const sourceId1 = edge1.getSource().cell
    const sourceId2 = edge1.getTarget().cell
    if (
      (sourceId1 === n1.id && sourceId2 === n2.id) ||
      (sourceId1 === n2.id && sourceId2 === n1.id)
    ) {
      edges.push(edge1)
    }
  })
  return edges
}

/**
 * 4.获取边的相关data（网口状态、网口流量）
 * @param {*} edge 新创建的边
 * @returns 返回填充数据之后的边
 */
export function getEdgeData (edge) {
  return new Promise((resolve, reject) => {
    const promiseArr = []
    const sNode = edge.getSourceNode()
    const tNode = edge.getTargetNode()
    const source = { sourceId: sNode.getData().sourceId }
    const target = { sourceId: tNode.getData().sourceId }
    const edgeData = {
      portStatus: {
        source: undefined,
        target: undefined
      },
      // 一条线的两端的入/出流量是一样的，所以只用取一边数据就可以
      net: {
        in: undefined,
        out: undefined,
        speed: undefined,
        usage: undefined
      }
    }
    let funcFlag = false
    if (edge.source.port) {
      funcFlag = true
      source.portId = edge.source.port
      const p = getEdgeDataFunc(sNode, source, edge, funcFlag).then(res => {
        edgeData.portStatus.source = res.portStatus
        if (res.net) {
          edgeData.net = res.net
        }
      })
      promiseArr.push(p)
    }
    if (edge.target.port) {
      funcFlag = !funcFlag
      target.portId = edge.target.port
      const p1 = getEdgeDataFunc(tNode, target, edge, funcFlag).then(res => {
        edgeData.portStatus.target = res.portStatus
        if (res.net) {
          edgeData.net = res.net
        }
      }).catch(() => {})
      promiseArr.push(p1)
    }
    if (promiseArr.length) {
      Promise.all(promiseArr).then(() => {
        edge.setData(edgeData)
        resolve(edge)
      }).catch((err) => {
        reject(err)
      })
    } else {
      edge.setData(edgeData)
      resolve(edge)
    }
  })
}
// 获取网口函数之--根据不同设备类型获取对应的网口函数
export function getInterfaceFunc (sourceType) {
  if (sourceType === 'server') {
    return {
      networkInterface: getServerInterfaceList,
      networkInterfaceMonitor: getServerMonitorHistory
    }
  }
  if (sourceType === 'storage') {
    return {
      networkInterface: getStorageInterfaceList,
      networkInterfaceMonitor: getStorageMonitorHistory
    }
  }
  if (sourceType === 'hypervisor') {
    return {
      networkInterface: getHypervisorList,
      networkInterfaceMonitor: getHypervisorMonitorHistory
    }
  }
  return {
    networkInterface: getNetworkDeviceNetworkInterfaceList,
    networkInterfaceMonitor: getNetworkDeviceMonitorHistory
  }
}
// 获取网口函数之--根据index获取网口监控项的key
function getNetKeys (index) {
  return [
    `net.if.in[ifHCInOctets.${index}]`,
    `net.if.out[ifHCOutOctets.${index}]`,
    `net.if.speed[ifHighSpeed.${index}]`
  ]
}
/**
 * 获取网口函数之--获取边的数据并写入到data
 * @param {*} node 节点
 * @param {*} connectData 链接桩链接信息（{sourceId, portId?}）
 * @param {*} edge 当前创建的边
 * @param {*} flag 是否要调去网口流量的标志
 * @returns
 */
export function getEdgeDataFunc (node, connectData, edge, flag) {
  return new Promise((resolve, reject) => {
    const promiseArr = []
    const edgeData = {
      portStatus: undefined
    }
    if (flag) {
      edgeData.net = {
        in: undefined,
        out: undefined,
        speed: undefined,
        usage: undefined
      }
    }

    const func = getInterfaceFunc(node.data.sourceType)
    func
      .networkInterface(connectData.sourceId, {
        network_interface_id: connectData.portId
      })
      .then(res => {
        const data = res.data.data
        if (data.length) {
          edgeData.portStatus = data[0].status
          if (flag) {
            const keys = getNetKeys(data[0].index)
            const p1 = func
              .networkInterfaceMonitor(connectData.sourceId, { key: keys[0] })
              .then(res => {
                edgeData.net.in = res.data.data.length
                  ? res.data.data[0].value
                  : 0
              }).catch(() => {})
            promiseArr.push(p1)
            const p2 = func
              .networkInterfaceMonitor(connectData.sourceId, { key: keys[1] })
              .then(res => {
                edgeData.net.out = res.data.data.length
                  ? res.data.data[0].value
                  : 0
              }).catch(() => {})
            promiseArr.push(p2)
            const p3 = func
              .networkInterfaceMonitor(connectData.sourceId, { key: keys[2] })
              .then(res => {
                edgeData.net.speed = res.data.data.length
                  ? res.data.data[0].value
                  : 0
              }).catch(() => {})
            promiseArr.push(p3)
          }
        } else {
          edgeData.portStatus = 'unknown'
          if (flag) {
            edgeData.net.in = 0
            edgeData.net.out = 0
            edgeData.net.speed = 0
            edgeData.net.usage = 0
          }
        }
        if (promiseArr.length) {
          Promise.all(promiseArr).then(() => {
            if (edgeData.portStatus) {
              let rate = 0
              const speed = edgeData.net.speed
              const max =
                edgeData.net.in > edgeData.net.out
                  ? edgeData.net.in
                  : edgeData.net.out
              if (max !== 0 && speed !== 0) {
                rate = (max / speed) * 100
              }
              edgeData.net.usage = rate
            }
            resolve(edgeData)
          })
        } else {
          resolve(edgeData)
        }
      }).catch(err => {
        reject(err)
      })
  })
}

/**
 * 5.根据所有边的告警状态/流量信息 更新主线样式
 * @param {*} edges 某两个设备之间的所有连线
 */
export function updateMainEdgeStyle (edges, mEdge) {
  const mainEdge = mEdge || edges.filter(edge => edge.visible)[0]

  // 1.先获取主线是否正常（只要有一条线不正常则主线异常），并记录流量数据到数组
  const inArr = []
  const outArr = []
  const statusArr = []
  const usageArr = []
  edges.forEach(edge => {
    if (!edge.visible) {
      const net = edge.data?.net
      const sPortStatus = edge.data?.portStatus.source
      const tPortStatus = edge.data?.portStatus.target
      let res = true

      if (sPortStatus && tPortStatus) {
        res = sPortStatus === 'normal' && tPortStatus === 'normal'
      } else if (sPortStatus) {
        res = sPortStatus === 'normal'
      } else if (tPortStatus) {
        res = tPortStatus === 'normal'
      }
      if (sPortStatus || tPortStatus) {
        inArr.push(net.in)
        outArr.push(net.out)
        usageArr.push(net.usage)
        statusArr.push(res)
      }
    }
  })

  // 获取线上的最大入/出流量/流量占比
  if (inArr.length) {
    const maxIn = Math.max(...inArr)
    mainEdge.data.maxNet.in = maxIn
    editLabel(mainEdge, '最大入流量', maxIn, 0, 0.25)
  } else {
    mainEdge.data.maxNet.in = undefined
    editLabel(mainEdge, '', '', 0, 0.25)
  }
  if (outArr.length) {
    const maxOut = Math.max(...outArr)
    mainEdge.data.maxNet.out = maxOut
    editLabel(mainEdge, '最大出流量', maxOut, 1, 0.75)
  } else {
    mainEdge.data.maxNet.out = undefined
    editLabel(mainEdge, '', '', 1, 0.75)
  }
  if (usageArr.length) {
    const maxUsage = Math.max(...usageArr)
    mainEdge.data.maxNet.usage = maxUsage
  } else {
    mainEdge.data.maxNet.usage = undefined
  }

  // 获取主线状态
  if (statusArr.length) {
    if (statusArr.findIndex(e => e === true) === -1) {
      mainEdge.data.status = 'abnormal'
      mainEdge.attr('line/stroke', EDGE_GRAY)
    } else {
      mainEdge.data.status = 'normal'
      mainEdge.attr(
        'line/stroke',
        getEdgeColorByUsage(mainEdge.data.maxNet.usage)
      )
    }
  } else {
    mainEdge.data.status = undefined
    mainEdge.attr('line/stroke', EDGE_DEFAULT)
  }
}
// 方案二：根据流量占比返回不同颜色
function getEdgeColorByUsage (rate) {
  if (rate >= 90) return EDGE_RED
  if (rate >= 60) return EDGE_YELLOW
  return EDGE_GREEN
}

/**
 * 删除边
 * @param {*} graph
 * @param {*} edges 要删除的边的数组
 * @param {*} mainEdge
 * @param {*} connectEdges 当前两节点之间的所有连线（不包括主线）
 * @returns
 */
export function removeEdges (graph, edges, mainEdge, connectEdges) {
  const removeEdges = graph.removeCells(edges)
  const sNode = mainEdge.getSourceNode()
  const tNode = mainEdge.getTargetNode()
  // 1.删除节点中对应的port
  removeEdges.forEach(rEdge => {
    const sPortId = rEdge.getSource().port
    const tPortId = rEdge.getTarget().port
    if (sPortId) sNode.removePort(sPortId)
    if (tPortId) tNode.removePort(tPortId)
  })

  if (connectEdges.length === 0) {
    // 2.若只剩下主线 则删除主线
    graph.removeEdge(mainEdge)
  } else {
    // 3.若还有别的连线 则更新主线的最大入/出流量以及颜色 并更新label
    updateMainEdgeStyle(connectEdges, mainEdge)
  }
  return removeEdges
}

// 初次渲染节点时 -- 样式进行同步
export function initNodeStyle (nodes, _this) {
  return new Promise(resolve => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      node.size.width = NODE_WIDTH
      node.size.height = NODE_HEIGHT
      if (node.ports) {
        node.ports.groups = networkTopologyPortsGroup.groups
      }

      // 通过判断是否有群组为main的链接桩
      if (
        !node.ports ||
        (node.ports && !node.ports.items.some(port => port.group === 'main'))
      ) {
        if (node.shape === 'vue-shape') {
          node.ports = JSON.parse(JSON.stringify(networkTopologyPortsGroup))

          // 判断 deviceTypeName
          if (!node.data.deviceTypeName) {
            if (node.data.sourceType === 'network_device') {
              node.data.deviceTypeName = _this.$t(
                `network_device_type.${node.data.deviceType}`
              )
            } else {
              node.data.deviceTypeName = _this.$t(
                `source_type.${node.data.sourceType}`
              )
            }
          }
        }
      }
    }
    resolve()
  })
}

// 初次渲染边时 -- 整理数据
export function initEdges (graph, edges) {
  return new Promise((resolve, reject) => {
    let newEdges = []
    // 1.去重（节点以及端口相同的线只保留一条）
    for (let i = 0; i < edges.length; i++) {
      const edge = edges[i]

      const sNode = graph.getCellById(edge.source.cell)
      const tNode = graph.getCellById(edge.target.cell)
      if (sNode && tNode) {
        const str1 = edge.source.cell + (edge.source.port ? edge.source.port : '')
        const str2 = edge.target.cell + (edge.target.port ? edge.target.port : '')
        const res = newEdges.find(nEdge => {
          const nStr1 = nEdge.source.cell + (nEdge.source.port ? nEdge.source.port : '')
          const nStr2 = nEdge.target.cell + (nEdge.target.port ? nEdge.target.port : '')
          return ((str1 === nStr1 && str2 === nStr2) || (str1 === nStr2 && str2 === nStr1))
        })
        if (!res) newEdges.push(edge)
      }
    }

    if (newEdges.length) {
      newEdges = newEdges.filter(edge => !edge.visible)

      // 2.分组（按照相同的入/出设备分组）
      const nEdgesGroup = {}
      newEdges.forEach(nEdge => {
        const source = nEdge.source
        const target = nEdge.target
        const str1 = source.cell + target.cell
        const str2 = target.cell + source.cell
        const index = Object.keys(nEdgesGroup).findIndex(key => (key === str1 || key === str2))
        if (index !== -1) nEdgesGroup[str1] ? nEdgesGroup[str1].push(nEdge) : nEdgesGroup[str2].push(nEdge)
        else nEdgesGroup[str1] = [nEdge]
      })

      const promiseArr = []
      for (const key in nEdgesGroup) {
        if (Object.hasOwnProperty.call(nEdgesGroup, key)) {
          const edgeArr = nEdgesGroup[key]

          const p = Promise.all(edgeArr.map((edge, index) => new Promise((resolve, reject) => {
            const source = edge.source
            const target = edge.target
            const sNode = graph.getCellById(source.cell)
            const tNode = graph.getCellById(target.cell)
            const portNames = { sourcePortName: '', targetPortName: '' }
            const promiseArr1 = []
            if (source.port && source.port !== 'main') {
              const func = getInterfaceFunc(sNode.getData().sourceType).networkInterface
              const p = func(sNode.getData().sourceId, {
                network_interface_id: source.port
              }).then(res => {
                const data = res.data.data
                if (data.length) {
                  portNames.sourcePortName = data[0].name
                }
              }).catch(() => {})
              promiseArr1.push(p)
            }
            if (target.port && target.port !== 'main') {
              const func = getInterfaceFunc(tNode.getData().sourceType).networkInterface
              const p = func(tNode.getData().sourceId, {
                network_interface_id: target.port
              }).then(res => {
                const data = res.data.data
                if (data.length) {
                  portNames.targetPortName = data[0].name
                }
              }).catch(() => {})
              promiseArr1.push(p)
            }
            Promise.all(promiseArr1).then(() => {
              if (source.port !== 'main' && target.port !== 'main') {
                const infos = {
                  edge: [source, target],
                  nodes: [sNode, tNode],
                  portNames
                }
                initEdgeAdd(graph, infos, index === 0).then((res) => {
                  resolve(res)
                }).catch(err => {
                  reject(err)
                })
              }
            })
          }))).then(res => {
          // 更新
            updateMainEdgeStyle(res.flat(1))
          })
          promiseArr.push(p)
        }
      }
      if (promiseArr.length) {
        Promise.all(promiseArr).then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
      } else resolve()
    } else resolve()
  })
}
// 初次渲染边时 -- 添加边
export function initEdgeAdd (graph, infos, ifStart = false) {
  return new Promise((resolve, reject) => {
    const { edge, nodes, portNames } = { ...infos }
    const source = edge[0]
    const target = edge[1]
    const sourceNode = nodes[0]
    const targetNode = nodes[1]
    let mainEdge

    // 1.若是连接到port 先添加该port到node中 先判断是否已经存在
    if (source.port && !sourceNode.hasPort(source.port)) {
      sourceNode.addPort({
        id: source.port,
        group: 'used',
        disabled: true,
        name: portNames.sourcePortName
      })
    }
    if (target.port && !targetNode.hasPort(target.port)) {
      targetNode.addPort({
        id: target.port,
        group: 'used',
        disabled: true,
        name: portNames.targetPortName
      })
    }

    // 2.判断是否要展示可见的主线
    if (ifStart) {
    // 若起止点为一个 则路由变为智能路由
      const obj1 = { cell: source.cell, port: 'main' }
      const obj2 = { cell: target.cell, port: 'main' }
      const edgeInfo = {
        ...networkTopologyEdgeAttrs,
        visible: true,
        labels: networkTopologyEdgeLabel,
        source: { ...edge[0], port: 'main' },
        target: { ...edge[1], port: 'main' }
      }
      if (obj1.cell === obj2.cell) {
        edgeInfo.router = {
          name: 'orth'
        }
        edgeInfo.connector = {
          name: 'rounded',
          args: {
            radius: 8
          }
        }
      }

      mainEdge = graph.addEdge(edgeInfo)
      mainEdge.setData({
        status: undefined,
        maxNet: {
          in: undefined,
          out: undefined,
          usage: undefined
        }
      })
    }

    // 3.添加当前创建的连线且不可见的渲染到画布上
    const newEdge = graph.addEdge({
      ...networkTopologyEdgeAttrs,
      source,
      target
    })
    // 1.获取边的相关数据
    getEdgeData(newEdge)
      .then(edge => {
        if (mainEdge) resolve([edge, mainEdge])
        else resolve(edge)
      }).catch(err => {
        reject(err)
      })
  })
}
