var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticClass:"topology-layout"},[_c('a-layout',[_c('a-layout-content',{staticStyle:{"background":"#ffffff","padding":"8px","border-radius":"0 0 8px 8px"}},[_c('div',{staticStyle:{"float":"left"}},[_c('a-space',{attrs:{"size":"large"}},[_c('a-space',[_c('div',{style:({
                borderRadius: '4px',
                background: _vm.EDGE_RED,
                fontSize: '12px',
                width: '14px',
                height: '6px',
                lineHeight: '17px',
                textAlign: 'center',
              })}),_c('div',[_vm._v("有告警 / 流量超过90%")])]),_c('a-space',[_c('div',{style:({
                borderRadius: '4px',
                background: _vm.EDGE_YELLOW,
                fontSize: '12px',
                width: '14px',
                height: '6px',
                lineHeight: '17px',
                textAlign: 'center',
              })}),_c('div',[_vm._v("流量低于90%")])]),_c('a-space',[_c('div',{style:({
                borderRadius: '4px',
                background: _vm.EDGE_GREEN,
                fontSize: '12px',
                width: '14px',
                height: '6px',
                lineHeight: '17px',
                textAlign: 'center',
              })}),_c('div',[_vm._v("流量低于60%")])]),_c('a-space',[_c('div',{style:({
                borderRadius: '4px',
                background: _vm.EDGE_GRAY,
                fontSize: '12px',
                width: '14px',
                height: '6px',
                lineHeight: '17px',
                textAlign: 'center',
              })}),_c('div',[_vm._v("无数据")])])],1)],1),_c('a-button',{staticStyle:{"color":"#faad14","float":"right","margin-bottom":"10px"},attrs:{"icon":"edit","type":"link"},on:{"click":function($event){return _vm.$router.push({ name: 'NetworkTopologyDetail', params: { id: _vm.id } })}}},[_vm._v(" 编辑 ")]),_c('div',{staticStyle:{"clear":"both"}}),_c('div',{staticStyle:{"position":"absolute","z-index":"100","font-size":"16px","color":"#1890ff"}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-icon',{attrs:{"type":"zoom-out"},on:{"click":_vm.zoomOut}}),_c('a-icon',{attrs:{"type":"zoom-in"},on:{"click":_vm.zoomIn}})],1)],1),_c('div',{attrs:{"id":"container"}})],1)],1),_c('network-device-drawer',{ref:"networkDeviceDrawer"}),_c('server-drawer',{ref:"serverDrawer"}),_c('storage-drawer',{ref:"storageDrawer"}),_c('edge-infos',{ref:"edgeInfoDrawer"}),_c('hypervisor-drawer',{ref:"hypervisorDrawer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }