import request from '@/utils/request'

const urlPrefix = '/network-topologies'

export function getNetworkTopology (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateNetworkTopology (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteNetworkTopology (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getNetworkTopologyList (params) {
  return request.get(urlPrefix, { params })
}

export function createNetworkTopology (data) {
  return request.post(urlPrefix, data)
}
