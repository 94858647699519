<template>
  <a-layout class="topology-layout">
    <a-layout>
      <a-layout-content
        style="background: #ffffff; padding: 8px; border-radius: 0 0 8px 8px"
      >
        <div style="float: left">
          <a-space size="large">
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_RED,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>有告警 / 流量超过90%</div>
            </a-space>
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_YELLOW,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>流量低于90%</div>
            </a-space>
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_GREEN,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>流量低于60%</div>
            </a-space>
            <a-space>
              <div
                :style="{
                  borderRadius: '4px',
                  background: EDGE_GRAY,
                  fontSize: '12px',
                  width: '14px',
                  height: '6px',
                  lineHeight: '17px',
                  textAlign: 'center',
                }"
              ></div>
              <div>无数据</div>
            </a-space>
          </a-space>
        </div>
        <a-button
          icon="edit"
          type="link"
          @click="$router.push({ name: 'NetworkTopologyDetail', params: { id } })"
          style="color: #faad14; float: right; margin-bottom: 10px"
        >
          编辑
        </a-button>
        <div style="clear: both"></div>
        <div style="position: absolute; z-index: 100; font-size: 16px; color: #1890ff">
          <a-space size="middle">
            <a-icon type="zoom-out" @click="zoomOut" />
            <a-icon type="zoom-in" @click="zoomIn" />
          </a-space>
        </div>
        <div id="container"></div>
      </a-layout-content>
    </a-layout>

    <network-device-drawer ref="networkDeviceDrawer"></network-device-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
    <edge-infos ref="edgeInfoDrawer"></edge-infos>
    <hypervisor-drawer ref="hypervisorDrawer"></hypervisor-drawer>
  </a-layout>
</template>

<script>
import { Graph } from '@antv/x6'
import {
  getConnectEdges,
  initNodeStyle,
  initEdges,
  EDGE_GRAY,
  EDGE_RED,
  EDGE_YELLOW,
  EDGE_GREEN
} from '@/utils/networkTopology'
import EdgeInfos from '../../NetworkTopologyDetail/modules/EdgeInfos'

export default {
  name: 'NetworkTopology',
  components: {
    EdgeInfos,
    NetworkDeviceDrawer: () =>
      import('@/components/drawer/NetworkDeviceDrawer'),
    StorageDrawer: () => import('@/components/drawer/StorageDrawer'),
    ServerDrawer: () => import('@/components/drawer/ServerDrawer'),
    HypervisorDrawer: () => import('@/components/drawer/HypervisorDrawer')
  },
  props: {
    edges: {
      type: Array,
      default: () => []
    },
    nodes: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    modified: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      canOperate: false,
      EDGE_GRAY,
      EDGE_RED,
      EDGE_YELLOW,
      EDGE_GREEN
    }
  },
  mounted () {
    this.initGraph()
  },
  beforeDestroy () {
    if (this.graph) this.graph.dispose()
  },
  methods: {
    initGraph () {
      const container = document.getElementById('container')
      this.graph = new Graph({
        container,
        autoResize: true,
        panning: true,
        grid: false,
        interacting: false
      })
      this.graph.on('edge:click', ({ edge }) => {
        if (this.canOperate) {
          this.$refs.edgeInfoDrawer.show(
            getConnectEdges(this.graph, edge),
            edge.getSourceNode(),
            edge.getTargetNode(),
            this.graph
          )
        }
      })

      this.graph.on('edge:mouseenter', ({ edge }) => {
        edge.attr('line/strokeDasharray', 0)
      })

      this.graph.on('edge:mouseleave', ({ edge }) => {
        edge.attr('line/strokeDasharray', 8)
      })

      this.graph.on('node:click', ({ e, node }) => {
        if (this.canOperate) {
          e.stopPropagation()
          if (node.data.sourceId) {
            const data = node.data
            if (data.sourceType === 'network_device') {
              this.$refs.networkDeviceDrawer.show(data.sourceId)
            } else if (data.sourceType === 'storage') {
              this.$refs.storageDrawer.show(data.sourceId)
            } else if (data.sourceType === 'hypervisor') {
              this.$refs.hypervisorDrawer.show(data.sourceId)
            } else {
              this.$refs.serverDrawer.show(data.sourceId)
            }
          }
        }
      })
      this.fetch()
    },
    zoomIn () {
      this.graph.zoom(0.1)
    },
    zoomOut () {
      this.graph.zoom(-0.1)
    },
    async fetch () {
      this.canOperate = false
      await initNodeStyle(this.nodes, this)
      this.graph.fromJSON({ nodes: this.nodes })
      this.graph.zoomToFit({ maxScale: 1.2, minScale: 0.6 })

      // 渲染边
      initEdges(this.graph, this.edges).then(() => {
      }).finally(() => {
        this.canOperate = true
      })
    }
  }
}
</script>

<style lang="less">
.topology-layout {
  background: #ffffff;
  border-radius: 8px;

  .ant-layout-header {
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
    background: #ffffff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 8px 8px 0 0;
  }

  #container {
    height: calc(100vh - 150px);
    background: #ffffff30;
    flex: 1;

    .x6-port,
    .x6-port-body {
      cursor: default !important;
    }
  }

  @keyframes topology-edge {
    to {
      stroke-dashoffset: -1000;
    }
  }
}
</style>
