<template>
  <div :style="containerStyle" class="network-topology-node-container">
    <!-- <img
      :src="imgUrl"
      :alt="nodeData.deviceType"
      :width="40"
      :height="40"
    /> -->
    <div id="node-img" :style="nodeImageStyle"></div>
    <a-tooltip :title="title" placement="bottom">
      <p class="network-topology-node-title">
        {{ title }}
      </p>
    </a-tooltip>
  </div>
</template>

<script>
import { getNetworkDevice } from '@/api/network-device'
import { getStorage } from '@/api/storage'
import { getServer } from '@/api/server'
import {
  getHypervisor
} from '@/api/hypervisor'

export default {
  name: 'NetworkNode',
  inject: ['getNode', 'getGraph'],
  data () {
    return {
      title: '',
      imgUrl: require('@/assets/images/noImg.png'),
      node: undefined,
      nodeData: {}
    }
  },
  computed: {
    containerStyle () {
      return {
        width: this.sourceId ? '70px' : '64px',
        textAlign: 'center',
        borderRadius: '50%'
      }
    },
    nodeImageStyle () {
      return {
        height: '40px',
        width: '40px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        marginLeft: '11px',
        backgroundImage: `url(${this.imgUrl})`
      }
    }
  },
  mounted () {
    this.node = this.getNode()
    this.nodeData = this.node.getData()

    this.nodeData.deviceType = this.nodeData.deviceType === undefined ? 'network_device' : this.nodeData.deviceType
    if (this.nodeData.sourceId) {
      this.title = this.nodeData.name
      this.changeNodeStyle(this.nodeData.sourceType, this.nodeData.sourceId)
    } else {
      this.title = this.nodeData.deviceTypeName
      this.imgUrl = require(`@/assets/images/apng/${this.nodeData.deviceType}-1.webp`)
    }
  },
  methods: {
    changeNodeStyle (type, id) {
      if (type === 'network_device') {
        getNetworkDevice(id)
          .then(res => {
            this.nodeData.name = res.data.name
            this.title = res.data.name
            this.nodeData.status = res.data.status || 'unknown'
            this.imgUrl = this.getImgUrl(this.nodeData.status)
          })
          .catch(() => {})
      } else if (type === 'server') {
        getServer(id)
          .then(res => {
            this.nodeData.name = res.data.name
            this.title = res.data.name
            this.nodeData.status = res.data.status || 'unknown'
            this.imgUrl = this.getImgUrl(this.nodeData.status)
          })
          .catch(() => {})
      } else if (type === 'storage') {
        getStorage(id)
          .then(res => {
            this.nodeData.name = res.data.name
            this.title = res.data.name
            this.nodeData.status = res.data.status || 'unknown'
            this.imgUrl = this.getImgUrl(this.nodeData.status)
          })
          .catch(() => {})
      } else if (type === 'hypervisor') {
        getHypervisor(id)
          .then(res => {
            this.nodeData.name = res.data.name
            this.title = res.data.name
            this.nodeData.status = res.data.status || 'unknown'
            this.imgUrl = this.getImgUrl(this.nodeData.status)
          })
          .catch(() => {})
      }
    },
    getImgUrl (status) {
      if (status === 'normal' || status === 'unknown') {
        return require(`@/assets/images/apng/${this.nodeData.deviceType}-1.webp`)
      }
      return require(`@/assets/images/apng/${this.nodeData.deviceType}-2.webp`)
    }
  }
}
</script>

<style lang="less">
.network-topology-node-container {
  cursor: default;
  p.network-topology-node-title {
    margin: 0;
    font-size: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    z-index: 999;
    cursor: default;
  }
}
</style>
