<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '网络拓扑编辑' : '网络拓扑创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="网络设备" prop="networkDevices">
        <multi-source-select
          v-model="form.networkDevices"
          :disabled="isUpdate"
          source-type="network_device"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  createNetworkTopology,
  getNetworkTopology,
  updateNetworkTopology
} from '@/api/network-topology'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'

export default {
  name: 'InputDrawer',
  components: {
    MultiSourceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        description: '',
        networkDevices: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getNetworkTopology(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              description: data.description,
              networkDevices: data.network_devices
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        description: '',
        networkDevices: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            description: this.form.description
          }
          this.loading = true
          if (this.isUpdate) {
            updateNetworkTopology(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            if (this.form.networkDevices) {
              params.network_device_ids = this.form.networkDevices.map(
                networkDevice => networkDevice.id
              )
            }
            createNetworkTopology(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
